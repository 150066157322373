import React from "react";
import { Layout } from "../../components/Layout";
import leanyLabsImg from "./leanylabs.jpg";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { Seo } from "../../components/Seo";
import { Person } from "../../components/Person";
import { Section, SectionTitle } from "../../components/Section";
import cn from "classnames";
import * as styles from "./about.module.scss";
import {
  Article,
  ArticleContent,
  ArticleHeader,
} from "../../components/Article";
import { PageHeader, PageTitle } from "../../components/PageHeader";

const AboutFeatured: React.FC = () => {
  return (
    <StaticImage
      src="./leanylabs.jpg"
      alt="LeanyLabs Team"
      placeholder="tracedSVG"
      quality={90}
      formats={["auto", "webp"]}
    />
  );
};

const AboutLeanyLabs: React.FC = () => {
  const [textRef, textInView] = useInView();

  return (
    <Section
      ref={textRef}
      containerOnly
      className={cn("show-up", { visible: textInView })}
    >
      <ArticleHeader>
        <PageTitle>About LeanyLabs</PageTitle>
      </ArticleHeader>
      <ArticleContent>
        <p>
          LeanyLabs is for businesses seeking a reliable software partner to
          assist with their web application development.
        </p>

        <p>
          From more than a dozen years of experience working as employees in
          different software outsourcing companies, we've seen how the process
          looks from the inside. Those companies did their best to meet clients'
          expectations. They've delivered excellent working products,
          occasionally wasting a bit too much time on management overhead.
        </p>
        <p>
          Few businesses, unfortunately, were more driven by profitability and
          not always provided perfect service. Some of them grabbed every
          project they could get their hands on, even if it were evident that
          they lack technical experience or expertise in the industry to deliver
          the best results. Others were over-engineering even the simplest
          applications, convincing everyone involved that the infrastructure
          should handle Facebook-level load and the requirements will become
          exponentially more complicated in the future.
        </p>
        <p>
          Professional engineers should design good architecture and write the
          right code for the job in front of them, and not always build the best
          starship they possibly can.
        </p>
        <p>
          A year before creating LeanyLabs, we got involved in a startup. We
          were the developers who built the prototype necessary to raise initial
          funding and then iterated on the MVP until it continuously made decent
          profits. The CEO relied on our analytical minds to refine the business
          model and all the math around it. It was somewhat successful, yet we
          had little control over high-level decisions and were pretty cold to
          the niche. After hiring excellent developers to replace us, we’ve sold
          our equity and left the project to pursue new challenges.
        </p>
        <p>
          Startup experience provided an opportunity to see everything from our
          former client's perspective.
        </p>
        <p>
          Even Ukrainian startups, with easy access to a large pool of talented
          IT professionals, face pretty much the same problems with software
          development as our clients from all over the world:
        </p>

        <ul className="list">
          <li>
            <strong>Hiring</strong> great developers is a long process that
            drags the attention away from working on what matters — the product
            itself.
          </li>

          <li>
            <strong>Coordinating</strong> a team of developers requires a lot of
            experience.
          </li>

          <li>
            <strong>Delegating</strong> side projects and products for new
            markets will keep the core team focused on achieving the current
            goals.
          </li>

          <li>
            In-house developers might be lacking{" "}
            <strong>unique expertise</strong> with new horizons in front of
            them.
          </li>

          <li>
            Having full-time employees for doing infrequent work is too{" "}
            <strong>expensive</strong>.
          </li>
        </ul>

        <p>
          By combining our{" "}
          <strong>technical expertise and tremendous experience</strong> working
          in service companies with a much <strong>better understanding</strong>{" "}
          of startups' problems, needs, and wants, we decided to create a unique
          company dedicated to solving them better than everyone else.
        </p>
        <p>At LeanyLabs, we:</p>
        <ul>
          <li>
            <strong>Focus on full-stack web development.</strong> That's our
            primary technical expertise, where we're proud to deliver impressive
            results by continually perfecting our craft. Sorry, no WordPress,
            landing pages, or marketing.
          </li>

          <li>
            <strong>Care about people.</strong> Building good relationships with
            both our clients and our employees is a top priority. We are
            building a culture where everyone is encouraged to share their ideas
            and provide honest feedback. Our clients count on full transparency,
            attention, and immediate support in urgent situations. Our
            developers continuously learn from each other, and everyone has a
            personal growth plan designed by our lead experts.
          </li>

          <li>
            <strong>Improve the product</strong> by collaborating on their
            initial ideas and creating an even better solution together.
            Sometimes we have previous experience with similar applications, and
            sometimes it's just a fresh look from a users' perspective. Even
            asking the right questions often helps to escape the brainstorming
            bubble.
          </li>

          <li>
            <strong>Take every project and every client seriously.</strong>{" "}
            We're not looking for quick and easy money. Our clients either
            receive the professional and reliable service they deserve, or we
            honestly tell them why we're unable to help and suggest other
            companies we trust personally.
          </li>

          <li>
            <strong>Avoid unnecessary management overhead.</strong> We're not
            fans of spending precious hours every day listening to non-relevant
            talks at different meetings. Usually, we do quick retrospectives and
            sprint planning with the whole team and everything else in
            one-on-ones as needed. On some projects, we also do very short daily
            standups together with the client team.
          </li>
        </ul>
        <p>
          Those are our core principles and values. We haven’t tried to make
          them fancy and outstanding. Instead, they are deliberately{" "}
          <strong> meaningful, simple, and easy to follow.</strong>
        </p>
      </ArticleContent>
    </Section>
  );
};

type FounderProps = {
  avatar: React.ReactNode;
  name: string;
  linkedIn: string;
  role: string;
};

const Founder: React.FC<FounderProps> = ({
  avatar,
  name,
  linkedIn,
  role,
  children,
}) => {
  const [ref, inView] = useInView();

  return (
    <div
      ref={ref}
      className={cn("my-2 show-up", {
        visible: inView,
      })}
    >
      <div className={styles.founder}>
        <Person
          avatar={avatar}
          name={name}
          linkedIn={linkedIn}
          position={role}
        />
      </div>
      <div>{children}</div>
    </div>
  );
};

const CoreTeam: React.FC = () => {
  return (
    <Section>
      <SectionTitle>Meet the Core Team</SectionTitle>
      <Founder
        name="Mykola Pustovoychenko"
        avatar={
          <StaticImage
            src="./mykola.jpg"
            alt="Mykola Pustovoychenko"
            width={240}
            height={240}
            formats={["auto", "webp"]}
          />
        }
        linkedIn="https://www.linkedin.com/in/mykola-pustovoychenko-b7788051/"
        role="Client Engagement & Operations"
      >
        <p>
          Mykola had won many competitions in chemistry and even was a jury
          member in world-class ones. Six months before defending his Ph.D.
          dissertation, he suddenly fell in love with programming and abandoned
          chemistry.
        </p>
        <p>
          Learning the basics and becoming a junior JavaScript developer took
          only a year thanks to his sharp mind, dedication, and a habit of small
          gradual improvements. A couple of years later, he became an expert in
          JavaScript, a certified scrum master, and got promoted to a team
          leader. During our startup ventures, he also finished a product
          management course.
        </p>
        <p>
          Mykola truly likes people and finds new friends everywhere. His
          reputation as a doer that always holds his word helped the company get
          on its feet.
        </p>
        <p>
          LeanyLabs fulfills Mykola's dream to extend his values and experience
          beyond one person, creating a world-class team that helps people and
          companies achieve their goals and solve their problems by crafting
          tailored software solutions.
        </p>
        <p>
          We trust him to build good relationships with the clients, hire
          awesome people, mentor them, and orchestrate the delivery process, so
          everything goes smoothly like a swiss watch. He makes sure that
          everyone in the company achieves their personal goals, stays motivated
          and productive all the time.
        </p>
      </Founder>
      <Founder
        name="Marian Zagoruiko"
        avatar={
          <StaticImage
            src="./marian.jpg"
            alt="Marian Zagoruiko"
            width={240}
            height={240}
            formats={["auto", "webp"]}
          />
        }
        linkedIn="https://www.linkedin.com/in/marian-zagoruiko-41482731/"
        role="Technical Expertise"
      >
        <p>
          Marian started tinkering with computers in middle school. Soon after
          learning programming, engineering for the World Wide Web became his
          passion.
        </p>
        <p>
          He got a job as a junior software engineer in the largest Ukrainian
          outsourcing enterprise while still being a student. Working together
          with hundreds of people on a massive project, he quickly gained
          diverse experience in the field and became a senior engineer.
        </p>
        <p>
          Love learning new technologies and digging into technical details
          gradually forged him as a unique expert. Another 800+ employee company
          hired Marian on a JavaScript competency lead position. Soon he started
          a strategically important project, leading the Ukrainian team working
          on a well-known Israeli startup in the digital media industry.
        </p>
        <p>
          Together they incorporated new technologies and improved the product
          making the development process more enjoyable and the codebase much
          cleaner. The same team achieved more than before on every sprint,
          improving the UX and implementing new features a lot faster.
        </p>
        <p>
          In LeanyLabs, Marian continues to pursue web development excellence,
          keeping his hand on the pulse of new technologies. He works on the
          most challenging projects, makes complicated architectural decisions,
          and guides the most difficult tasks to successful completion.
          Expertise with AWS internals allows him to debug, explain and fix
          unexpected problems much faster than every DevOps we know.
        </p>
        <p>
          Marian creates effective personal growth plans even for our senior
          developers.
        </p>
      </Founder>
      <Founder
        name="Andriy Obrizan"
        avatar={
          <StaticImage
            src="./andriy.jpg"
            alt="Andriy Obrizan"
            width={240}
            height={240}
            formats={["auto", "webp"]}
          />
        }
        linkedIn="https://www.linkedin.com/in/andriyobrizan/"
        role="Growth & Stability"
      >
        <p>
          Andriy was fortunate to read a book about Turbo Pascal, an ancient
          programming language, before seeing a real computer when he was 10.
        </p>
        <p>
          He won multiple programming contests in school and created interesting
          pet projects from physics simulations to games, 3d engines, and even a
          small DOS-compatible operating system. After satisfying his
          competitive spirit by winning a nation-wide programming competition
          for students, he got a part-time job in a small outsourcing company,
          becoming a project leader in few years, even before graduating.
        </p>
        <p>
          His career continued as a lead developer of high-performance trading
          software that processed vast amounts of data with consistent
          sub-millisecond latency, running on specialized servers right next to
          NYSE. On this job, Andriy fell in love with analytics, data insights,
          and fast code. In free time, with Blockchain and IoT.
        </p>
        <p>
          As a real backend developer, Andriy takes care of the LeanyLabs
          backend operations. Legal, compliance, security, accounting,
          bookkeeping, finances, tech, office management, and other stuff. He
          enjoys digging into data, optimizing processes, spying on competitors,
          doing marketing, and absorbing new information required to generate
          various growth-hacking strategies for the business.
        </p>
      </Founder>
    </Section>
  );
};

// Photos, story
// - Mykola - path to success. like / dream / hope
// - Marian - knows this sh1t extremely well. trust / expertise / get sh1t done
// - Andriy - seen a lot. reliability / guarantee

const Page: React.FC = () => {
  return (
    <Layout>
      <Seo
        ogFullTitle
        pureTitle
        title="About LeanyLabs"
        description="LeanyLabs is for businesses seeking a reliable software partner to assist with their web application development."
        ogImage={leanyLabsImg}
        maxImagePreview="large"
      />
      <Article fullWidth>
        <AboutFeatured />
        <AboutLeanyLabs />
        <CoreTeam />
      </Article>
    </Layout>
  );
};

export default Page;
