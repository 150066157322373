import React from "react";
import * as styles from "./Article.module.scss";
import { PageHeader } from "./PageHeader";

type Props = {
  fullWidth?: boolean;
};

export const Article: React.FC<Props> = ({ fullWidth, children }) => {
  return (
    <article className={!fullWidth ? styles.article : undefined}>
      {children}
    </article>
  );
};

export const ArticleHeader: React.FC<Props> = ({ children }) => {
  return <PageHeader className={styles.header}>{children}</PageHeader>;
};

export const ArticleContent: React.FC<Props> = ({ children }) => {
  return <div className={styles.content}>{children}</div>;
};
